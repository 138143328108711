<template>
    <div class="contact-us">
        <div class="banner-wrap" :style="`background: url(${bg}) center no-repeat; background-size: 100% 100%;`">
            <div class="main-content-wrap content-wrap">
                <div class="title">{{ $t('rap.contactUs.banner.title') }}</div>
                <div class="sub-text">{{ $t('rap.contactUs.banner.desc') }}</div>
            </div>
        </div>
        <div class="main-content-wrap content-wrap">
            <div class="subit-content">
                <div class="submit-wrap">
                    <el-form ref="ruleForm"
                        :model="ruleForm"
                        :rules="rules">
                        <el-row :gutter="10">
                            <el-col :span="12">
                                <el-form-item prop="name" :label="$t('rap.contactUs.submit.your-name')">
                                    <el-input v-model="ruleForm.name"
                                        clearable>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item prop="lastName" :label="$t('rap.contactUs.submit.your-last-name')">
                                    <el-input v-model="ruleForm.lastName"
                                        clearable>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-form-item prop="email" :label="$t('rap.contactUs.submit.email')">
                            <el-input v-model="ruleForm.email"
                                clearable>
                            </el-input>
                        </el-form-item>
                        <el-form-item prop="infomation" :label="$t('rap.contactUs.submit.infomation')">
                            <el-input v-model="ruleForm.infomation"
                                class="infomation"
                                type="textarea"
                                :style="`min-height: 104px;`"
                                :autosize="{ minRows: 4, maxRows: 15}"
                                clearable>
                            </el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button class="submit"
                                size="small"
                                type="primary"
                                @click="doSubmit">
                                {{ $t('rap.common.button.submit') }}
                            </el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <span class="line"></span>
                <div class="info-wrap">
                    <div class="question-list">
                        <div class="question-item" v-for="(question, index) in questions" :key="index">
                            <img class="point" src="@/assets/contact/contact-green-dot.png" alt=""> {{ question }}
                        </div>
                    </div>
                    <div class="info-list">
                        <div class="info-item" v-for="(item, index) in infoList" :key="index">
                            <img :src="item.img">
                            <div class="text">{{ item.text }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { valideEmail } from '@/utils/pattern.js'
export default {
    data () {
        return {
            bg: require('@/assets/contact/contact-bg.png'),
            ruleForm: {
                name: '',
                lastName: '',
                email: '',
                infomation: ''
            },
            rules: {
                name: [{ required: true, message: this.$t('rap.contactUs.tip.your-name-empty'), trigger: 'change' }],
                lastName: [{ required: true, message: this.$t('rap.contactUs.tip.your-last-name-empty'), trigger: 'change' }],
                email: [{ required: true, validator: valideEmail, trigger: 'change' }],
                infomation: [{ required: true, message: this.$t('rap.contactUs.tip.infomation-empty'), trigger: 'change' }]
            },
            questions: [
                this.$t('rap.contactUs.info.question-01'),
                this.$t('rap.contactUs.info.question-02'),
                this.$t('rap.contactUs.info.question-03'),
                this.$t('rap.contactUs.info.question-04')
            ],
            infoList: [
                {
                    img: require('@/assets/contact/contact-phone-img.png'),
                    text: this.$t('rap.contactUs.info.mobile')
                },
                {
                    img: require('@/assets/contact/contact-email-img.png'),
                    text: this.$t('rap.contactUs.info.email')
                },
                {
                    img: require('@/assets/contact/contact-address-img.png'),
                    text: this.$t('rap.contactUs.info.address')
                }
            ]
        }
    },
    methods: {
        // 提交
        doSubmit () {
            this.$refs.ruleForm &&
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.reqSubmit()
                }
            })
        },
        reqSubmit () {
            const loginUserId = ''
            this.$api.contactUs({
                first_name: this.ruleForm.name,
                last_name: this.ruleForm.lastName,
                username: this.ruleForm.email,
                id: loginUserId,
                information: this.ruleForm.infomation
            }).then((res) => {
                if (res.code === 0) {
                    this.$message.success(this.$t('rap.common.tip.submit-success'))
                    this.$refs.ruleForm &&
                    this.$refs.ruleForm.resetFields()
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    .contact-us{
        background-color: white;
        overflow: hidden;
        .banner-wrap{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 330px;
            .title{
                font-size: 34px;
                font-family: PingFangSC, PingFangSC-Medium;
                font-weight: 500;
                color: #ffffff;
                margin-bottom: 25px;
            }
            .sub-text{
                opacity: 0.96;
                font-size: 25px;
                font-family: PingFangSC, PingFangSC-Regular;
                color: #ffffff;
            }
        }
        .content-wrap{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
        }
        .subit-content{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-start;
            margin: 73px 0;
            position: relative;
            .submit-wrap{
                flex-shrink: 0;
                min-width: 484px;
                /deep/ .el-form-item{
                    margin-bottom: 13px;
                }
                /deep/ .el-form-item__label{
                    font-size: 14px;
                    font-family: PingFangSC, PingFangSC-Regular;
                    font-weight: 400;
                    color: #000000;
                    &::before {
                        color: #F75050;
                    }
                }
                /deep/ .el-input__inner,
                /deep/ .el-textarea__inner{
                    background: #f7f7f7;
                    border-radius: 2px;
                    border-width: 0;
                }
                .submit{
                    width: 96px;
                    border-radius: 1px;
                    float: right;
                    font-size: 14px;
                }
            }
            .info-wrap{
                padding: 25px 0 0 0;
                .question-list{
                    display: flex;
                    flex-direction: column;
                    flex-shrink: 0;
                    .question-item{
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        .point{
                            display: inline-block;
                            width: 14px;
                            height: 14px;
                            margin-right: 10px;
                        }
                        &:not(:last-of-type) {
                            margin-bottom: 12px;
                        }
                    }
                }
                .info-list {
                    margin-top: 46px;
                    display: flex;
                    flex-direction: column;
                    .info-item{
                        display: flex;
                        flex-direction: row;
                        align-items: flex-start;
                        &:not(:last-of-type) {
                            margin-bottom: 12px;
                        }
                        img{
                            display: inline-block;
                            width: 24px;
                            height: 24px;
                            margin-right: 6px;
                        }
                        .text{
                            width: 450px;
                            font-size: 14px;
                            font-family: PingFangSC, PingFangSC-Regular;
                            font-weight: 400;
                            color: #000000;
                            line-height: 20px;
                        }
                    }
                }
            }
            .line{
                display: inline-block;
                width: 2px;
                height: 370px;
                background: #f8fafe;
                margin: 0 80px;
            }
        }
    }
</style>
