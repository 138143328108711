/** form 表单校验 */
/**
 * 校验邮箱
 */
/* eslint-disable */
export const valideEmail = (rule, value, callback) => {
    const reg = /^\w+(?:\.\w+){0,1}@[a-zA-Z0-9]{2,14}(?:\.[a-z]{2,4}){1,2}$/
    if (rule.required && !value) {
        callback(new Error(window.vm.$t('rap.common.tip.email-empty')))
    } else if (!reg.test(value)) {
        callback(new Error(window.vm.$t('rap.common.tip.email-fomat')))
    }
    callback()
}

export const validePhone = (rule, value, callback) => {
    if (rule.required && !value) {
        callback(new Error('Your phone is required!'))
    }
    callback()
}
